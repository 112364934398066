var $ = jQuery.noConflict();
const logos = document.querySelectorAll(".logo-reveal");
window.addEventListener("load", function () {
    for (const logo of logos) {
        logo.classList.add("reveal");
    }
});

$(function () {
    console.log("JS Loaded 🚀");

    $("select").niceSelect();


    $('.product-carousel').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: '<svg class="slick-buttons slick-prev" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 440 440" style="enable-background:new 0 0 440 440;" xml:space="preserve"><style type="text/css">.st0{fill:none;stroke:#000000;stroke-width:50;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:133.3333;}</style><path class="st0" d="M320,386.7L153.3,220L320,53.3"/></svg>',
        nextArrow: '<svg class="slick-next slick-buttons" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 440 440" style="enable-background:new 0 0 440 440;" xml:space="preserve"><style type="text/css">.st0{fill:none;stroke:#000000;stroke-width:50;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:133.3333;}</style><path class="st0" d="M153.3,53.3L320,220L153.3,386.7"/></svg>',
        autoplay: true,
        autoplaySpeed: 3000,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                arrows: true,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 480,
                arrows: true,
                settings: {
                    centerPadding: '40px',
                    slidesToShow: 1
                }
            }
        ]
    });

    /*Masonary active*/
    var $grid = $('.iso-grid-wrap').packery({
        // options
        itemSelector: '.isotope-item',
        // gutter: 15,
        resize: true
    });
    $grid.imagesLoaded().progress(function () {
        $grid.packery('layout');
    });
    var $gridMas = $('.iso-mas-grid-wrap').packery({
        // options
        itemSelector: '.isotope-mas-item',
        // gutter: 15,
        resize: true
    });
    $gridMas.imagesLoaded().progress(function () {
        $gridMas.packery('layout');
    });



    /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
           Sticky Header
    <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
    window.onscroll = function () {
        // scrollFunction();
    };

    function scrollFunction() {
        if (
            document.body.scrollTop > 50 ||
            document.documentElement.scrollTop > 50
        ) {
            $(".site-header--sticky").addClass("scrolling");
        } else {
            $(".site-header--sticky").removeClass("scrolling");
        }
        if (
            document.body.scrollTop > 700 ||
            document.documentElement.scrollTop > 700
        ) {
            $(".site-header--sticky.scrolling").addClass("reveal-header");
        } else {
            $(".site-header--sticky.scrolling").removeClass("reveal-header");
        }
    }

    if (jQuery(".product-details-vertical-slider").length > 0) {
        $('.product-details-vertical-slider').slick({
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 8,
            slidesToScroll: 1,
            arrows: false,
            focusOnSelect: true,
            vertical: true,
            verticalSwiping: true,
            adaptiveHeight: true,
            asNavFor: '.product-details-slider',
            responsive: [{
                breakpoint: 768,
                settings: {
                    vertical: false,
                    slidesToShow: 1,
                    arrows: true,
                    prevArrow: '<svg class="slick-buttons slick-prev" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 440 440" style="enable-background:new 0 0 440 440;" xml:space="preserve"><style type="text/css">.st0{fill:none;stroke:#000000;stroke-width:50;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:133.3333;}</style><path class="st0" d="M320,386.7L153.3,220L320,53.3"/></svg>',
                    nextArrow: '<svg class="slick-next slick-buttons" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 440 440" style="enable-background:new 0 0 440 440;" xml:space="preserve"><style type="text/css">.st0{fill:none;stroke:#000000;stroke-width:50;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:133.3333;}</style><path class="st0" d="M153.3,53.3L320,220L153.3,386.7"/></svg>',
                    slidesToScroll: 1,
                }
            }
            ]
        });
    }

    if (jQuery(".product-details-slider").length > 0) {
        $('.product-details-slider').slick({
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            adaptiveHeight: false,
            asNavFor: '.product-details-vertical-slider'
        });
    }
});

